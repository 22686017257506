html{
  font-size: 14px;
}
@media (min-width: @screen-md) {
  html{
    font-size: 16px;
  }
}
@media (min-width: @screen-sl) {
  html{
    font-size: 16px * 1.2;
  }
}
body{
  font-family: @font1;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-flex{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: inherit;
}

.bg-paper{
  position: relative;
  background-color: rgb(237,236,234);
  background-image: url('../../images/bg-concrete.webp');
  background-position: center top;
  background-repeat: repeat;
  // background-image: linear-gradient(90deg, rgba(237,236,234,1) 0%, rgba(241,240,238,1) 45%, rgba(241,240,238,1) 55%, rgba(237,236,234,1) 100%);
}

.to-top{
  position: fixed;
  z-index: 99;
  bottom: 1rem;
  right: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  display: inline-block;
  border-radius: 50%;
  opacity: 0;
  transform: translateY(4rem);
  transition: opacity .3s, transform .3s;
  &.active{
    opacity: 1;
    transform: translateY(0);
  }
  img{
    width: 100%;
    height: 100%;
  }
}

.svg-defs{
  width: 0;
  height: 0;
}