@sectionHeight: 550px;

// global
.section{
  position: relative;
  height: auto;
  min-height: 100vh;
}

// hero
.section-hero-track{
  position: relative;
  height: 100vh * 1.5;
  // min-height: @sectionHeight * 1.5;
}
.section-hero{
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #333;
  color: #fff;
  @media (min-width: @screen-lg) {
    height: 100vh;
  }
  .video-wrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
    img{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }
    iframe{
      position: absolute;
      z-index: 1;
      opacity: 0;
      transition: opacity 1s;
      &.ready{
        opacity: 1;
      }
    }
  }
  .content-1{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    .hero-logo{
      transition: opacity .5s, transform .5s;
    }
  }
  .content-2{
    position: relative;
    z-index: 3;
    height: 100%;
    min-height: inherit;
    background-color: rgba(0,0,0,.8);
    pointer-events: none;
    opacity: 0;
    transition: opacity .5s;
    .hero-desc{
      padding: 3rem 1rem;
      transform: translateY(30px);
      transition: transform .5s;
      text-align: center;
    }
    .desc-1{
      max-width: 21.8rem;
    }
    .desc-2{
      max-width: 37.5rem;
    }
  }
  .content-scroll{
    position: absolute;
    z-index: 4;
    bottom: 1rem;
    left: 50%;
    width: 5rem;
    transform: translateX(-50%);
  }
  // state
  &.shown{
    .content-1 .hero-logo{
      opacity: 0;
      transform: translateY(-50px);
    }
    .content-2{
      pointer-events: initial;
      opacity: 1;
      transition-delay: .3s;
    }
    .content-2 .hero-desc{
      transform: translateY(0);
      transition-delay: .3s;
    }
  }
}

// Kagoshima
.section-kagoshima{
  position: relative;
  background-color: #eee;
  background-image: url(../../images/bg-concrete.webp);
  background-repeat: repeat;
  background-position: center;
  .kagoshima-body{
    position: relative;
    width: 41rem;
    max-width: 100%;
  }
  .logo{
    position: relative;
    z-index: 1;
  }
  .desc{
    position: relative;
    z-index: 1;
    flex-grow: 1;
    &-title{
      // font-family: @font2;
      font-size: 1.75rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.35em;
      margin-bottom: 0.75rem;
    }
    &-img{
      text-align: right;
      margin-top: 1rem;
      @media (min-width: @screen-md) {
        position: absolute;
        top: 100%;
        right: 0;
      }
    }
  }
  .bg{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 80%;
    height: auto;
    object-fit: cover;
  }
}

// separator
.section-separator{
  position: relative;
  background-color: #000;
  background-image: url(../../images/img-essencebg.png);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  min-height: 50vh;
  letter-spacing: 0.4em;
  font-size: 1.125rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9), 1px 3px 15px rgba(0, 0, 0, 0.75);
  @supports (-webkit-overflow-scrolling: touch) {
    @media (max-width: @screen-sm) {
      background-attachment: scroll;
    }
  }
  h2{
    text-transform: uppercase;
    font-family: @font2;
    font-weight: 700;
    letter-spacing: 0.2em;
  }
}

// Kuruzu
.section-kurozu{
  position: relative;
  z-index: 5;
  background-color: #fff;
  min-height: 50vh;
  overflow: hidden;
  .kurozu-body{
    position: relative;
    max-width: 41rem;
  }
  .desc{
    position: relative;
    z-index: 1;
    &-title{
      position: relative;
      display: inline-block;
      font-size: 1.125rem;
      font-family: @font2;
      font-weight: 700;
      border: 2px solid #000;
      padding: 0.35em 1em;
      &:before{
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        border: 1px solid rgba(0,0,0,.5);
      }
    }
  }
  .img{
    margin-right: -3rem;
    > img{
      width: 100%;
      height: auto;
    }
    @media (min-width: @screen-md) {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      > img{
        width: auto;
      }
    }
  }
}

// products
// #z-index
// - bg (pattern): 2
// - bar: 3
// - faded efx: 4
// - petals: 8
// - product: 11
.section-products{
  position: relative;
  overflow: hidden;
  > .container{
    padding-bottom: 6.25rem;
  }
  .product-item{
    position: relative;
    z-index: 11;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 1rem 0;
    @media (min-width: @screen-lg) {
      padding: 7rem 0;
    }
    &-logo{
      position: relative;
      flex: 0 0 40%;
      text-align: center;
      font-size: 0.85rem;
      font-weight: 700;
      letter-spacing: 0.2em;
      padding-left: 1rem;
      padding-right: 1rem;
      svg, img{
        width: 100%;
        height: auto;
      }
      @media (min-width: @screen-lg) {
        flex: 0 0 20%;
      }
    }
    &-img{
      flex: 0 0 60%;
      .product-img{
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        > img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: auto;
        }
      }
      @media (min-width: @screen-lg) {
        flex: 0 0 40%;
      }
    }
    &-desc{
      flex: 0 0 100%;
      &-inner{
        font-size: 0.92rem;
        line-height: 1.4;
        background-color: rgba(255,255,255,.8);
        backdrop-filter: blur(6px);
        border-radius: 8px;
        @media (min-width: @screen-lg) {
          max-width: 27rem;
        }
      }
      .tagline{
        font-family: @font2;
        font-weight: 700;
        text-transform: uppercase;
      }
      .icon{
        margin: 1rem auto;
      }
      .desc{
        margin-bottom: 1.5rem;
        font-weight: 700;
      }
      .highlight{
        font-weight: bold;
        font-size: 0.92em;
      }
      @media (min-width: @screen-lg) {
        flex: 0 0 40%;
        &-inner{
          background-color: transparent;
          backdrop-filter: none;
        }
      }
    }

    // product theme
    &.product-item-shuga{
      .product-item-logo,
      .tagline,
      .highlight{
        color: #5ec1b2;
      }
    }
    &.product-item-oiru{
      .product-item-logo,
      .tagline,
      .highlight{
        color: #cbb3d7;
      }
    }
    &.product-item-mizu{
      .product-item-logo,
      .tagline,
      .highlight{
        color: #63ad9f;
      }
    }
    &.product-item-daitto{
      .product-item-logo,
      .tagline,
      .highlight{
        color: #cab1d6;
      }
    }

    @media (min-width: @screen-lg) {
      .product-item-img .product-img{
        opacity: 0;
        transform: rotate(10deg);
      }
      .product-item-img .product-img.__in{
        opacity: 1;
        transform: none;
        transition: transform .5s cubic-bezier(0.22, 1, 0.36, 1), opacity .5s;
        // transition-delay: .5s;
      }
    }
  }

  // sub objects
  .obj-faded{
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
  .obj-bg{
    position: absolute;
    z-index: 2;
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    > img{
      position: absolute;
      width: 70%;
      height: 25%;
      object-fit: contain;
    }
  }
  .obj-bar{
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    left: 0;
    width: 40%;
    background-color: #eeeff1;
    box-shadow: 15px 0 30px rgba(0,0,0,.15);
    pointer-events: none;
    @media (min-width: @screen-lg) {
      width: 20%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .obj-petals{
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    > img{
      position: absolute;
      width: 25%;
      min-width: 250px;
      height: auto;
    }
  }
  .obj-petals-2{
    position: absolute;
    pointer-events: none;
    z-index: 8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    @media (min-width: @screen-lg) {
      top: -70px;
      left: -5%;
      right: -5%;
      bottom: 100px;
    }
  }
}

// essential
.section-essential{
  position: relative;
  z-index: 10;
  @media (min-width: @screen-lg) {
    margin-top: -3rem;
    min-height: 100vh;
  }
  .bg-img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ceb9d8;
    background-image: linear-gradient(180deg, #d1bddf, #a3c0e0);
    > img{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
    > .gradient{
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      height: 200px;
      background-image: linear-gradient(180deg, rgba(206,185,216,0) 0%, #d3bddc 100%);
    }
    > .clip{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100px;
      width: 100%;
    }
  }
  .container{
    padding-top: 2rem;
    padding-bottom: 100px;
  }
  .circle{
    position: relative;
    width: 14rem;
    min-width: 14rem;
    height: 14rem;
    border-radius: 50%;
    @media (min-width: @screen-lg) {
      width: 19rem;
      min-width: 19rem;
      height: 19rem;
    }
    .front, .back, .overlay{ 
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: opacity .3s;
    }
    .overlay{
      opacity: 0;
    }
    .back{
      opacity: 0;
    }
    &:hover{
      .front{
        opacity: 0;
      }
      .back, .overlay{
        opacity: 1;
      }
    }
    &-lite{
      background-image: linear-gradient(196deg, #69BCB1 0%, #005080 100%);
      @media (min-width: @screen-lg) {
        margin-top: 188px;
      }
    }
    &-advance{
      background-image: linear-gradient(196deg, #CAB1D6 0%, #4D8CCA 100%);
      margin-left: auto;
    }
  }
  .products{
    position: relative;
    z-index: 1;
    pointer-events: none;
    margin-top: -50px;
    margin-bottom: -50px;
    &-logo{
      position: absolute;
      right: 0;
      width: 53%;
      text-align: center;
      img{
        width: 100%;
        height: auto;
      }
    }
    &-images{
      img{
        margin-top: 0;
        width: 100%;
        height: auto;
      }
    }
    @media (min-width: @screen-lg) {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 65%;
      height: 100%;
      transform: translate(-50%, -50%);
      &-logo{
        right: 15%;
        top: 17%;
        width: 45%;
      }
      &-images{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        img{
          margin-top: 3rem;
        }
      }
    }
  }
}

// gallery
.section-gallery{
  position: relative;
  z-index: 2;
  background-color: #f4f4f4;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url(../../images/bg-concrete.webp);
  @media (min-width: @screen-lg) {
    min-height: 100vh;
  }
  .gallery{
    max-width: 62rem;
    width: 100%;
    &-view{
      position: relative;
      width: 100%;
      padding-bottom: (9 / 16 * 100%);
      background-color: #000;
    }
    &-thumbs{
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
    }
  }
  .gallery-item{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    iframe, img{
      width: 100%;
      height: 100%;
    }
    img{
      object-fit: cover;
    }
    // state
    &.active{
      display: block;
    }
  }
  .thumb{
    position: relative;
    overflow: hidden;
    flex: 0 0 33.333%;
    @media (min-width: @screen-md) {
      flex: 1;
    }
    &-inner{
      position: relative;
      z-index: 2;
      padding-bottom: (9 / 16 * 100%);
    }
    &-content{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: #fff;
      font-size: 0.85em;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      text-align: center;
    }
    &-cover{
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:before,
    &:after{
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:before{
      background-color: #000;
      opacity: .5;
      transition: opacity .3s;
    }
    &:after{
      background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
      transform: translateY(100%);
      transition: transform .3s ease-out;
    }
    // state
    &:hover:before{
      opacity: .8;
    }
    &.active:after{
      transform: translateY(0);
    }
  }
}

/* purgecss start ignore */
// testimonials
.section-testimonials{
  position: relative;
  z-index: 1;
  background-color: #f4f4f4;
  background-image: url(../../images/bg-concrete.webp);
  overflow: hidden;
  box-shadow: 0 5px 33px rgba(0,0,0,.2);
  h2{
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-transform: uppercase;
    letter-spacing: .3em;
  }
  svg.defs-only{
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }
  .testimonials-wrap{
    background-color: #ceb9d8;
    background-image: linear-gradient(to right, #ceb9d8, #a3c0e0);
    display: flex;
    flex-wrap: nowrap;
    padding-top: 50px;
    padding-bottom: 100px;
  }
  .testimonial{
    width: 22.5rem;
    height: auto;
    font-size: 1rem;
    padding: 1rem 1.5rem;
    transition: transform .3s;
    &-content{
      position: relative;
      height: 100%;
    }
    &-front{
      position: relative;
      height: 100%;
      padding: 2rem 1rem;
      text-align: center;
      font-size: 0.85rem;
      .bg{
        filter: drop-shadow(1px 2px 4px rgba(0,0,0,.15));
      }
    }
    &-back{
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      padding: 1em;
      margin: 1em;
      line-height: 1.3;
      color: #fff;
      opacity: 0;
      transition: opacity .5s;
    }
    &-more{
      position: absolute;
      z-index: 10;
      bottom: 0;
      right: 0;
      width: 60px;
      height: 60px;
      color: #fff;
      text-transform: uppercase;
    }
    // elements
    .bg{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
    .label{
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 1.2;
    }
    .more{
      position: absolute;
      bottom: 14px;
      right: 0;
      left: 0.652em;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: 700;
      letter-spacing: 0.1em;
    }
    .img{
      position: relative;
      margin: 1em 0;
      img{
        width: 100%;
        height: auto;
      }
    }
    .img-txt{
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      background-color: #edecec;
      display: flex;
      justify-content: space-between;
      padding: 0 0.2em;
      font-size: 0.6em;
      font-weight: 700;
    }
    .name{
      text-transform: uppercase;
      margin-bottom: 0.25rem;
      font-size: 0.92rem;
    }
    .sub{
      font-size: 0.85em;
    }
    // state
    &.swiper-slide-active{
      transform: scale(1.1);
    }
    &.hovered{
      .testimonial-back{
        opacity: 1;
      }
    }
  }
}
/* purgecss end ignore */

// cta
.section-cta{
  position: relative;
  overflow: hidden;
  .obj-petals{
    position: absolute;
    z-index: 1;
    left: -3%;
    right: -3%;
    top: 5%;
    bottom: 10%;
    pointer-events: none;
  }
}

// faq
.section-faq{
  position: relative;
  padding-top: 7rem;
  .cert-box{
    position: relative;
    width: 95%;
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8rem 8rem 0 0;
    &:before{
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: inherit;
      background-color: #c3afd4;
      background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
    }
    &-content{
      position: relative;
      height: 100%;
      background-color: #f6f6f6;
      border-radius: inherit;
      padding: 100px 1rem 200px 1rem;
      margin-bottom: -100px;
    }
    // elements
    .cert-img{
      position: relative;
      z-index: 5;
      flex: 0 0 40%;
      text-align: center;
      img{
        max-width: 100%;
        height: auto;
      }
    }
    .cert-body{
      position: relative;
      z-index: 3;
      flex: 0 0 60%;
    }
    .cert-title{
      font-weight: 700;
      font-size: 2rem;
      letter-spacing: 0.3em;
      img{
        max-width: 100%;
      }
    }
    .cert-bar{
      position: relative;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 700;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      position: relative;
      display: flex;
      align-items: center;
      min-height: 3.5rem;
      padding: 0.5rem 0;
      img{
        max-width: 100%;
        height: auto;
      }
      &:before{
        content: '';
        position: absolute;
        height: 100%;
        left: -8%;
        right: -8%;
        background-color: #c3afd4;
        background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
        @media (min-width: @screen-lg) {
          left: -98%;
          right: -18%; 
        }
      }
    }
    .cert-desc{
      margin-top: 1rem;
    }
  }
  .faq-box{
    position: relative;
    z-index: 5;
    background-color: #fff;
    border-radius: 2rem;
    padding: 2rem;
    width: 100%;
    max-width: 68rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    .box-title{
      position: absolute;
      z-index: 10;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 200px;
      text-align: center;
      background-color: @main500;
      color: #fff;
      font-weight: 700;
      font-size: 1.25rem;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      margin: 0;
      padding: 1rem;
      border-radius: 1rem 1rem 0 0;
    }
    .faq{
      font-size: 0.92rem;
      &-heading{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem 1rem 0;
        margin: 0;
        user-select: none;
        cursor: pointer;
        &:before{
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background-color: #c3afd4;
          background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
        }
        &:after{
          content: '-';
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          font-family: Helvetica;
          font-size: 0.92em;
          color: #fff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1.125rem;
          height: 1.125rem;
          border-radius: 50%;
          background-color: #c3afd4;
          background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
        }
      }
      &-body{
        background-color: #efe7f4;
        overflow: hidden;
        transition: height .3s;
      }
      // state
      &.collapsed{
        .faq-heading:after{
          content: '+';
        }
        .faq-body{
          height: 0 !important;
        }
      }
    }
  }
}

// contact
.section-contact{
  position: relative;
  z-index: 2;
  padding-top: 7rem;
  padding-bottom: 7rem;
  h2{
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.25em;
  }
  .contact-box{
    display: block;
    width: 30rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
.contact-modal{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  &.shown{
    display: block;
    .contact-modal-content{
      opacity: 0;
      transform: translateY(-2rem);
      transition: opacity .3s, transform .3s;
    }
  }
  &.shown.__in{
    .contact-modal-content{
      opacity: 1;
      transform: translateY(0);
    }
  }
  &-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &-dialog{
    position: relative;
    display: flex;
    align-items: center;
    max-width: 28rem;
    margin: 1.75rem auto;
    min-height: ~"calc(100% - 3.5rem)";
  }
  &-content{
    position: relative;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 1rem;
    outline: 0;
    box-shadow: 0 3px 35px rgba(0,0,0,.15);
  }
}

// Petals
#Petals{
  width: 100%;
  height: auto;
}
#Petals2{
  width: 100%;
  height: auto;
}
@media (min-width: @screen-lg) {
  #Petals, #Petals2{
    height: 100%;
    > path{
      opacity: 0;
      transition: opacity .2s;
      &.__in{
        opacity: 1;
      }
    }
  }
}