.form-input{
  .label{
    display: block;
    color: @color500;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
}

.input{
  display: inline-block;
  width: 100%;
  border: none;
  background-color: #fff;
  font-family: @font1;
  font-weight: 700;
  font-size: 1.125rem;
  padding: 0.35em 1em;
  height: 50px;
  outline: none;
  transition: box-shadow .3s;
  &:focus{
    box-shadow: 0 0 0 2px #cab2d6;
  }
}
textarea.input{
  height: auto;
  min-height: 5rem;
  scrollbar-width: thin;
  &::-webkit-scrollbar{
    width: 8px;
    background: #f0f0f0;
  }
  &::-webkit-scrollbar-thumb{
    background: #cdcdcd;
  }
}