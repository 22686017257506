.btn{
  display: inline-block;
  padding: 0.85em 1.2em;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #fff;
  border: none;
  background-color: #c3afd4;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
  transition: background .3s, opacity .3s;

  &:disabled{
    opacity: .5;
  }
}