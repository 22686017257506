.footer{
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #c3afd4;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
  color: #fff;
  overflow: hidden;
  .copyright{
    font-size: 0.85rem;
  }
  .socials{
    margin-left: -0.35rem;
    margin-right: -0.35rem;
    .social-item{
      margin-left: 0.35rem;
      margin-right: 0.35rem;
      fill: currentColor;
      color: #fff;
      filter: drop-shadow(0 1px 3px rgba(0,0,0,.3));
      opacity: 1;
      transition: opacity .3s;
      &:hover{
        opacity: 0.5;
      }
    }
  }
}