/**
 * LESS variables
 */
/*!
 * Bootstrap Reboot v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
h6, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}
h6 {
  font-size: 1rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
b {
  font-weight: bolder;
}
small {
  font-size: 0.875em;
}
sub {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
input,
button,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  text-transform: none;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}
textarea {
  resize: vertical;
}
/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
iframe {
  border: 0;
}
[hidden] {
  display: none !important;
}
/*!
 * Bootstrap Utilities v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
.d-flex {
  display: flex !important;
}
.d-none {
  display: none !important;
}
.position-relative {
  position: relative !important;
}
.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.mt-4 {
  margin-top: 1.5rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 1rem !important;
}
.mb-4 {
  margin-bottom: 1.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.text-center {
  text-align: center !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
/* rtl:begin:remove */
/* rtl:end:remove */
@media (min-width: 768px) {

  .d-md-block {
    display: block !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {

  .d-lg-block {
    display: block !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }
}
/*!
 * Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * **customized version - no utils
 */
.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
   .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
   .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
   .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
   .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
   .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}
@media (min-width: 992px) {

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}
@font-face {
  font-family: 'Oregon LDO';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Oregon LDO.woff') format('woff');
}
@font-face {
  font-family: 'Oregon LDO';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Oregon LDO Bold.woff') format('woff');
}
@font-face {
  font-family: 'Optima';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Optima Bold.woff') format('woff');
}
.text-gradient {
  display: inline-block;
  background-image: linear-gradient(to right, #ceb9d8, #a3c0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-2 {
  display: inline-block;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.btn {
  display: inline-block;
  padding: 0.85em 1.2em;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #fff;
  border: none;
  background-color: #c3afd4;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
  transition: background 0.3s, opacity 0.3s;
}
.btn:disabled {
  opacity: 0.5;
}
.form-input .label {
  display: block;
  color: #222222;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.input {
  display: inline-block;
  width: 100%;
  border: none;
  background-color: #fff;
  font-family: 'Oregon LDO', Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  padding: 0.35em 1em;
  height: 50px;
  outline: none;
  transition: box-shadow 0.3s;
}
.input:focus {
  box-shadow: 0 0 0 2px #cab2d6;
}
textarea.input {
  height: auto;
  min-height: 5rem;
  scrollbar-width: thin;
}
textarea.input::-webkit-scrollbar {
  width: 8px;
  background: #f0f0f0;
}
textarea.input::-webkit-scrollbar-thumb {
  background: #cdcdcd;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
/* Auto Height */
.swiper-pagination {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-pagination-bullet {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.75rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #625999;
}
@media (min-width: 992px) {
  .fade {
    opacity: 0;
  }
  .fade.__in {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
  .fade-up {
    opacity: 0;
    transform: translateY(2rem);
  }
  .fade-up.__in {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0, 0, 0.25, 1);
  }
  .fade-left {
    opacity: 0;
    transform: translateX(2rem);
  }
  .fade-left.__in {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0, 0, 0.25, 1);
  }
  .fade-right {
    opacity: 0;
    transform: translateX(-3rem);
  }
  .fade-right.__in {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0, 0, 0.25, 1);
  }
  .delay-1.__in {
    transition-delay: 0.1s;
  }
  .delay-2.__in {
    transition-delay: 0.2s;
  }
  .delay-4.__in {
    transition-delay: 0.4s;
  }
  .slow-1.__in {
    transition-duration: 0.75s;
  }
}
html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1400px) {
  html {
    font-size: 19.2px;
  }
}
body {
  font-family: 'Oregon LDO', Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: inherit;
}
.bg-paper {
  position: relative;
  background-color: #edecea;
  background-image: url('../images/bg-concrete.webp');
  background-position: center top;
  background-repeat: repeat;
}
.to-top {
  position: fixed;
  z-index: 99;
  bottom: 1rem;
  right: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  display: inline-block;
  border-radius: 50%;
  opacity: 0;
  transform: translateY(4rem);
  transition: opacity 0.3s, transform 0.3s;
}
.to-top.active {
  opacity: 1;
  transform: translateY(0);
}
.to-top img {
  width: 100%;
  height: 100%;
}
.svg-defs {
  width: 0;
  height: 0;
}
.header {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  height: 3.75rem;
}
.header-link {
  display: inline-block;
  padding: 1em 0;
  margin: 0 0.75em;
  color: #fff;
  fill: currentColor;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 0.1em;
  transition: color 0.3s;
}
.header-link:hover {
  color: #c09eca;
}
.header .logo-white {
  display: block;
}
.header .logo-color {
  display: none;
}
.header.sticky {
  position: fixed;
  background-color: #ffffff;
  color: #000;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
}
.header.sticky.__in {
  opacity: 1;
  transition: opacity 0.5s;
}
.header.sticky .header-link {
  color: #676767;
}
.header.sticky .header-link:hover {
  color: #c09eca;
}
.header.sticky .logo-white {
  display: none;
}
.header.sticky .logo-color {
  display: block;
}
.dropdown-wrap {
  position: relative;
}
.dropdown {
  display: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dropdown-content {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background-color: #fff;
  border-radius: 1rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  min-width: 100px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
}
.dropdown-content:before {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}
.dropdown-item {
  display: block;
  text-align: center;
  padding: 0.5em 0.75em;
  color: #000;
  text-decoration: none;
  transition: color 0.3s;
}
.dropdown-item:hover {
  color: #c09eca;
}
.dropdown.shown {
  display: block;
}
.dropdown.shown .dropdown-content {
  opacity: 0;
  transform: translate(-50%, 1rem);
  transition: opacity 0.3s, transform 0.3s ease-out;
}
.dropdown.shown.__in .dropdown-content {
  opacity: 1;
  transform: translate(-50%, 0);
}
.navigation {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: none;
}
.navigation-link {
  position: relative;
  padding: 1rem;
  text-align: center;
}
.navigation-link a {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c09eca;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s;
}
.navigation-link a:before,
.navigation-link a:after {
  content: '';
  display: inline-block;
  width: 1.3em;
  height: 1em;
  margin: 0 0.75em;
  background-image: url('../images/ic-menu-shell.svg');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s;
}
.navigation-link a:hover,
.navigation-link a:focus {
  color: #fff;
  outline: none;
}
.navigation-link a:focus:before,
.navigation-link a:focus:after,
.navigation-link a:hover:before,
.navigation-link a:hover:after {
  opacity: 1;
}
.navigation-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0 1rem;
  color: #fff;
  fill: currentColor;
}
.navigation-close:hover {
  color: #c09eca;
}
.navigation.active {
  display: block;
  opacity: 0;
  transition: opacity 0.5s;
}
.navigation.active .navigation-link {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.navigation.active .navigation-link:nth-child(1) {
  transition-delay: 0.54285714s;
}
.navigation.active .navigation-link:nth-child(2) {
  transition-delay: 0.58571429s;
}
.navigation.active .navigation-link:nth-child(3) {
  transition-delay: 0.62857143s;
}
.navigation.active .navigation-link:nth-child(4) {
  transition-delay: 0.67142857s;
}
.navigation.active .navigation-link:nth-child(5) {
  transition-delay: 0.71428571s;
}
.navigation.active .navigation-link:nth-child(6) {
  transition-delay: 0.75714286s;
}
.navigation.active .navigation-link:nth-child(7) {
  transition-delay: 0.8s;
}
.navigation.active.__in {
  opacity: 1;
}
.navigation.active.__in .navigation-link {
  opacity: 1;
}
.footer {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #c3afd4;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
  color: #fff;
  overflow: hidden;
}
.footer .copyright {
  font-size: 0.85rem;
}
.footer .socials {
  margin-left: -0.35rem;
  margin-right: -0.35rem;
}
.footer .socials .social-item {
  margin-left: 0.35rem;
  margin-right: 0.35rem;
  fill: currentColor;
  color: #fff;
  filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.3));
  opacity: 1;
  transition: opacity 0.3s;
}
.footer .socials .social-item:hover {
  opacity: 0.5;
}
.section {
  position: relative;
  height: auto;
  min-height: 100vh;
}
.section-hero-track {
  position: relative;
  height: 150vh;
}
.section-hero {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #333;
  color: #fff;
}
@media (min-width: 992px) {
  .section-hero {
    height: 100vh;
  }
}
.section-hero .video-wrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
.section-hero .video-wrap img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
}
.section-hero .video-wrap iframe {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}
.section-hero .video-wrap iframe.ready {
  opacity: 1;
}
.section-hero .content-1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.section-hero .content-1 .hero-logo {
  transition: opacity 0.5s, transform 0.5s;
}
.section-hero .content-2 {
  position: relative;
  z-index: 3;
  height: 100%;
  min-height: inherit;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}
.section-hero .content-2 .hero-desc {
  padding: 3rem 1rem;
  transform: translateY(30px);
  transition: transform 0.5s;
  text-align: center;
}
.section-hero .content-2 .desc-1 {
  max-width: 21.8rem;
}
.section-hero .content-2 .desc-2 {
  max-width: 37.5rem;
}
.section-hero .content-scroll {
  position: absolute;
  z-index: 4;
  bottom: 1rem;
  left: 50%;
  width: 5rem;
  transform: translateX(-50%);
}
.section-hero.shown .content-1 .hero-logo {
  opacity: 0;
  transform: translateY(-50px);
}
.section-hero.shown .content-2 {
  pointer-events: initial;
  opacity: 1;
  transition-delay: 0.3s;
}
.section-hero.shown .content-2 .hero-desc {
  transform: translateY(0);
  transition-delay: 0.3s;
}
.section-kagoshima {
  position: relative;
  background-color: #eee;
  background-image: url(../images/bg-concrete.webp);
  background-repeat: repeat;
  background-position: center;
}
.section-kagoshima .kagoshima-body {
  position: relative;
  width: 41rem;
  max-width: 100%;
}
.section-kagoshima .logo {
  position: relative;
  z-index: 1;
}
.section-kagoshima .desc {
  position: relative;
  z-index: 1;
  flex-grow: 1;
}
.section-kagoshima .desc-title {
  font-size: 1.75rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.35em;
  margin-bottom: 0.75rem;
}
.section-kagoshima .desc-img {
  text-align: right;
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .section-kagoshima .desc-img {
    position: absolute;
    top: 100%;
    right: 0;
  }
}
.section-kagoshima .bg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 80%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.section-separator {
  position: relative;
  background-color: #000;
  background-image: url(../images/img-essencebg.png);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  min-height: 50vh;
  letter-spacing: 0.4em;
  font-size: 1.125rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9), 1px 3px 15px rgba(0, 0, 0, 0.75);
}
@supports (-webkit-overflow-scrolling: touch) {
  @media (max-width: 576px) {
    .section-separator {
      background-attachment: scroll;
    }
  }
}
.section-separator h2 {
  text-transform: uppercase;
  font-family: 'Optima', Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.2em;
}
.section-kurozu {
  position: relative;
  z-index: 5;
  background-color: #fff;
  min-height: 50vh;
  overflow: hidden;
}
.section-kurozu .kurozu-body {
  position: relative;
  max-width: 41rem;
}
.section-kurozu .desc {
  position: relative;
  z-index: 1;
}
.section-kurozu .desc-title {
  position: relative;
  display: inline-block;
  font-size: 1.125rem;
  font-family: 'Optima', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 2px solid #000;
  padding: 0.35em 1em;
}
.section-kurozu .desc-title:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.section-kurozu .img {
  margin-right: -3rem;
}
.section-kurozu .img > img {
  width: 100%;
  height: auto;
}
@media (min-width: 768px) {
  .section-kurozu .img {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  .section-kurozu .img > img {
    width: auto;
  }
}
.section-products {
  position: relative;
  overflow: hidden;
}
.section-products > .container {
  padding-bottom: 6.25rem;
}
.section-products .product-item {
  position: relative;
  z-index: 11;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 1rem 0;
}
@media (min-width: 992px) {
  .section-products .product-item {
    padding: 7rem 0;
  }
}
.section-products .product-item-logo {
  position: relative;
  flex: 0 0 40%;
  text-align: center;
  font-size: 0.85rem;
  font-weight: 700;
  letter-spacing: 0.2em;
  padding-left: 1rem;
  padding-right: 1rem;
}
.section-products .product-item-logo svg,
.section-products .product-item-logo img {
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .section-products .product-item-logo {
    flex: 0 0 20%;
  }
}
.section-products .product-item-img {
  flex: 0 0 60%;
}
.section-products .product-item-img .product-img {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
.section-products .product-item-img .product-img > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .section-products .product-item-img {
    flex: 0 0 40%;
  }
}
.section-products .product-item-desc {
  flex: 0 0 100%;
}
.section-products .product-item-desc-inner {
  font-size: 0.92rem;
  line-height: 1.4;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  border-radius: 8px;
}
@media (min-width: 992px) {
  .section-products .product-item-desc-inner {
    max-width: 27rem;
  }
}
.section-products .product-item-desc .tagline {
  font-family: 'Optima', Helvetica, Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.section-products .product-item-desc .icon {
  margin: 1rem auto;
}
.section-products .product-item-desc .desc {
  margin-bottom: 1.5rem;
  font-weight: 700;
}
.section-products .product-item-desc .highlight {
  font-weight: bold;
  font-size: 0.92em;
}
@media (min-width: 992px) {
  .section-products .product-item-desc {
    flex: 0 0 40%;
  }
  .section-products .product-item-desc-inner {
    background-color: transparent;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
  }
}
.section-products .product-item.product-item-shuga .product-item-logo,
.section-products .product-item.product-item-shuga .tagline,
.section-products .product-item.product-item-shuga .highlight {
  color: #5ec1b2;
}
.section-products .product-item.product-item-oiru .product-item-logo,
.section-products .product-item.product-item-oiru .tagline,
.section-products .product-item.product-item-oiru .highlight {
  color: #cbb3d7;
}
.section-products .product-item.product-item-mizu .product-item-logo,
.section-products .product-item.product-item-mizu .tagline,
.section-products .product-item.product-item-mizu .highlight {
  color: #63ad9f;
}
.section-products .product-item.product-item-daitto .product-item-logo,
.section-products .product-item.product-item-daitto .tagline,
.section-products .product-item.product-item-daitto .highlight {
  color: #cab1d6;
}
@media (min-width: 992px) {
  .section-products .product-item .product-item-img .product-img {
    opacity: 0;
    transform: rotate(10deg);
  }
  .section-products .product-item .product-item-img .product-img.__in {
    opacity: 1;
    transform: none;
    transition: transform 0.5s cubic-bezier(0.22, 1, 0.36, 1), opacity 0.5s;
  }
}
.section-products .obj-faded {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  background: linear-gradient(to bottom, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}
.section-products .obj-bg {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.section-products .obj-bg > img {
  position: absolute;
  width: 70%;
  height: 25%;
  -o-object-fit: contain;
     object-fit: contain;
}
.section-products .obj-bar {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  width: 40%;
  background-color: #eeeff1;
  box-shadow: 15px 0 30px rgba(0, 0, 0, 0.15);
  pointer-events: none;
}
@media (min-width: 992px) {
  .section-products .obj-bar {
    width: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.section-products .obj-petals {
  position: absolute;
  z-index: 8;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.section-products .obj-petals > img {
  position: absolute;
  width: 25%;
  min-width: 250px;
  height: auto;
}
.section-products .obj-petals-2 {
  position: absolute;
  pointer-events: none;
  z-index: 8;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
@media (min-width: 992px) {
  .section-products .obj-petals-2 {
    top: -70px;
    left: -5%;
    right: -5%;
    bottom: 100px;
  }
}
.section-essential {
  position: relative;
  z-index: 10;
}
@media (min-width: 992px) {
  .section-essential {
    margin-top: -3rem;
    min-height: 100vh;
  }
}
.section-essential .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ceb9d8;
  background-image: linear-gradient(180deg, #d1bddf, #a3c0e0);
}
.section-essential .bg-img > img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.section-essential .bg-img > .gradient {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 200px;
  background-image: linear-gradient(180deg, rgba(206, 185, 216, 0) 0%, #d3bddc 100%);
}
.section-essential .bg-img > .clip {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  width: 100%;
}
.section-essential .container {
  padding-top: 2rem;
  padding-bottom: 100px;
}
.section-essential .circle {
  position: relative;
  width: 14rem;
  min-width: 14rem;
  height: 14rem;
  border-radius: 50%;
}
@media (min-width: 992px) {
  .section-essential .circle {
    width: 19rem;
    min-width: 19rem;
    height: 19rem;
  }
}
.section-essential .circle .front,
.section-essential .circle .back,
.section-essential .circle .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
}
.section-essential .circle .overlay {
  opacity: 0;
}
.section-essential .circle .back {
  opacity: 0;
}
.section-essential .circle:hover .front {
  opacity: 0;
}
.section-essential .circle:hover .back,
.section-essential .circle:hover .overlay {
  opacity: 1;
}
.section-essential .circle-lite {
  background-image: linear-gradient(196deg, #69BCB1 0%, #005080 100%);
}
@media (min-width: 992px) {
  .section-essential .circle-lite {
    margin-top: 188px;
  }
}
.section-essential .circle-advance {
  background-image: linear-gradient(196deg, #CAB1D6 0%, #4D8CCA 100%);
  margin-left: auto;
}
.section-essential .products {
  position: relative;
  z-index: 1;
  pointer-events: none;
  margin-top: -50px;
  margin-bottom: -50px;
}
.section-essential .products-logo {
  position: absolute;
  right: 0;
  width: 53%;
  text-align: center;
}
.section-essential .products-logo img {
  width: 100%;
  height: auto;
}
.section-essential .products-images img {
  margin-top: 0;
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  .section-essential .products {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 65%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
  .section-essential .products-logo {
    right: 15%;
    top: 17%;
    width: 45%;
  }
  .section-essential .products-images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .section-essential .products-images img {
    margin-top: 3rem;
  }
}
.section-gallery {
  position: relative;
  z-index: 2;
  background-color: #f4f4f4;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url(../images/bg-concrete.webp);
}
@media (min-width: 992px) {
  .section-gallery {
    min-height: 100vh;
  }
}
.section-gallery .gallery {
  max-width: 62rem;
  width: 100%;
}
.section-gallery .gallery-view {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  background-color: #000;
}
.section-gallery .gallery-thumbs {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.section-gallery .gallery-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}
.section-gallery .gallery-item iframe,
.section-gallery .gallery-item img {
  width: 100%;
  height: 100%;
}
.section-gallery .gallery-item img {
  -o-object-fit: cover;
     object-fit: cover;
}
.section-gallery .gallery-item.active {
  display: block;
}
.section-gallery .thumb {
  position: relative;
  overflow: hidden;
  flex: 0 0 33.333%;
}
@media (min-width: 768px) {
  .section-gallery .thumb {
    flex: 1;
  }
}
.section-gallery .thumb-inner {
  position: relative;
  z-index: 2;
  padding-bottom: 56.25%;
}
.section-gallery .thumb-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 0.85em;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-align: center;
}
.section-gallery .thumb-cover {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.section-gallery .thumb:before,
.section-gallery .thumb:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.section-gallery .thumb:before {
  background-color: #000;
  opacity: 0.5;
  transition: opacity 0.3s;
}
.section-gallery .thumb:after {
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
  transform: translateY(100%);
  transition: transform 0.3s ease-out;
}
.section-gallery .thumb:hover:before {
  opacity: 0.8;
}
.section-gallery .thumb.active:after {
  transform: translateY(0);
}
.section-testimonials {
  position: relative;
  z-index: 1;
  background-color: #f4f4f4;
  background-image: url(../images/bg-concrete.webp);
  overflow: hidden;
  box-shadow: 0 5px 33px rgba(0, 0, 0, 0.2);
}
.section-testimonials h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.3em;
}
.section-testimonials svg.defs-only {
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  overflow: hidden;
}
.section-testimonials .testimonials-wrap {
  background-color: #ceb9d8;
  background-image: linear-gradient(to right, #ceb9d8, #a3c0e0);
  display: flex;
  flex-wrap: nowrap;
  padding-top: 50px;
  padding-bottom: 100px;
}
.section-testimonials .testimonial {
  width: 22.5rem;
  height: auto;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  transition: transform 0.3s;
}
.section-testimonials .testimonial-content {
  position: relative;
  height: 100%;
}
.section-testimonials .testimonial-front {
  position: relative;
  height: 100%;
  padding: 2rem 1rem;
  text-align: center;
  font-size: 0.85rem;
}
.section-testimonials .testimonial-front .bg {
  filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.15));
}
.section-testimonials .testimonial-back {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: 1em;
  margin: 1em;
  line-height: 1.3;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s;
}
.section-testimonials .testimonial-more {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 60px;
  color: #fff;
  text-transform: uppercase;
}
.section-testimonials .testimonial .bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.section-testimonials .testimonial .label {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.2;
}
.section-testimonials .testimonial .more {
  position: absolute;
  bottom: 14px;
  right: 0;
  left: 0.652em;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.1em;
}
.section-testimonials .testimonial .img {
  position: relative;
  margin: 1em 0;
}
.section-testimonials .testimonial .img img {
  width: 100%;
  height: auto;
}
.section-testimonials .testimonial .img-txt {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #edecec;
  display: flex;
  justify-content: space-between;
  padding: 0 0.2em;
  font-size: 0.6em;
  font-weight: 700;
}
.section-testimonials .testimonial .name {
  text-transform: uppercase;
  margin-bottom: 0.25rem;
  font-size: 0.92rem;
}
.section-testimonials .testimonial .sub {
  font-size: 0.85em;
}
.section-testimonials .testimonial.swiper-slide-active {
  transform: scale(1.1);
}
.section-testimonials .testimonial.hovered .testimonial-back {
  opacity: 1;
}
.section-cta {
  position: relative;
  overflow: hidden;
}
.section-cta .obj-petals {
  position: absolute;
  z-index: 1;
  left: -3%;
  right: -3%;
  top: 5%;
  bottom: 10%;
  pointer-events: none;
}
.section-faq {
  position: relative;
  padding-top: 7rem;
}
.section-faq .cert-box {
  position: relative;
  width: 95%;
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8rem 8rem 0 0;
}
.section-faq .cert-box:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-radius: inherit;
  background-color: #c3afd4;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
}
.section-faq .cert-box-content {
  position: relative;
  height: 100%;
  background-color: #f6f6f6;
  border-radius: inherit;
  padding: 100px 1rem 200px 1rem;
  margin-bottom: -100px;
}
.section-faq .cert-box .cert-img {
  position: relative;
  z-index: 5;
  flex: 0 0 40%;
  text-align: center;
}
.section-faq .cert-box .cert-img img {
  max-width: 100%;
  height: auto;
}
.section-faq .cert-box .cert-body {
  position: relative;
  z-index: 3;
  flex: 0 0 60%;
}
.section-faq .cert-box .cert-title {
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.3em;
}
.section-faq .cert-box .cert-title img {
  max-width: 100%;
}
.section-faq .cert-box .cert-bar {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 3.5rem;
  padding: 0.5rem 0;
}
.section-faq .cert-box .cert-bar img {
  max-width: 100%;
  height: auto;
}
.section-faq .cert-box .cert-bar:before {
  content: '';
  position: absolute;
  height: 100%;
  left: -8%;
  right: -8%;
  background-color: #c3afd4;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
}
@media (min-width: 992px) {
  .section-faq .cert-box .cert-bar:before {
    left: -98%;
    right: -18%;
  }
}
.section-faq .cert-box .cert-desc {
  margin-top: 1rem;
}
.section-faq .faq-box {
  position: relative;
  z-index: 5;
  background-color: #fff;
  border-radius: 2rem;
  padding: 2rem;
  width: 100%;
  max-width: 68rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}
.section-faq .faq-box .box-title {
  position: absolute;
  z-index: 10;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 200px;
  text-align: center;
  background-color: #cab2d6;
  color: #fff;
  font-weight: 700;
  font-size: 1.25rem;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  margin: 0;
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
}
.section-faq .faq-box .faq {
  font-size: 0.92rem;
}
.section-faq .faq-box .faq-heading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 0;
  margin: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
.section-faq .faq-box .faq-heading:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #c3afd4;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
}
.section-faq .faq-box .faq-heading:after {
  content: '-';
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-family: Helvetica;
  font-size: 0.92em;
  color: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 50%;
  background-color: #c3afd4;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
}
.section-faq .faq-box .faq-body {
  background-color: #efe7f4;
  overflow: hidden;
  transition: height 0.3s;
}
.section-faq .faq-box .faq.collapsed .faq-heading:after {
  content: '+';
}
.section-faq .faq-box .faq.collapsed .faq-body {
  height: 0 !important;
}
.section-contact {
  position: relative;
  z-index: 2;
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.section-contact h2 {
  font-weight: 700;
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}
.section-contact .contact-box {
  display: block;
  width: 30rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.contact-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.contact-modal.shown {
  display: block;
}
.contact-modal.shown .contact-modal-content {
  opacity: 0;
  transform: translateY(-2rem);
  transition: opacity 0.3s, transform 0.3s;
}
.contact-modal.shown.__in .contact-modal-content {
  opacity: 1;
  transform: translateY(0);
}
.contact-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.contact-modal-dialog {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 28rem;
  margin: 1.75rem auto;
  min-height: calc(100% - 3.5rem);
}
.contact-modal-content {
  position: relative;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 1rem;
  outline: 0;
  box-shadow: 0 3px 35px rgba(0, 0, 0, 0.15);
}
#Petals {
  width: 100%;
  height: auto;
}
#Petals2 {
  width: 100%;
  height: auto;
}
@media (min-width: 992px) {
  #Petals,
  #Petals2 {
    height: 100%;
  }
  #Petals > path,
  #Petals2 > path {
    opacity: 0;
    transition: opacity 0.2s;
  }
  #Petals > path.__in,
  #Petals2 > path.__in {
    opacity: 1;
  }
}

/*# sourceMappingURL=index.e76f35e0.css.map */
