/**
 * LESS variables
 */
// Colors variables
@main500    : #cab2d6;
@main300    : lighten(@main500, 12%);
@main700    : #446133;
@sub500     : #b0aa7e;
@sub300     : #d0c883;
@sub700     : darken(@sub500, 10%);
@color500   : #222222;

// Fonts
@font1      : 'Oregon LDO', Helvetica, Arial, sans-serif;
@font2      : 'Optima', Helvetica, Arial, sans-serif;

// Screen size
@screen-sm  : 576px;
@screen-md  : 768px;
@screen-lg  : 992px;
@screen-xl  : 1200px;
@screen-sl  : 1400px;
@screen-xs  : (@screen-sm - 1);
