// @import 'swiper/swiper';
// copy from swiper node_modules
.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}
.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}
.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-container-pointer-events {
  touch-action: pan-y;
  &.swiper-container-vertical {
    touch-action: pan-x;
  }
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

.swiper-container-horizontal.swiper-container-css-mode {
  > .swiper-wrapper {
    scroll-snap-type: x mandatory;
  }
}
.swiper-container-vertical.swiper-container-css-mode {
  > .swiper-wrapper {
    scroll-snap-type: y mandatory;
  }
}

// custom
.swiper-pagination{
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-pagination-bullet{
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.75rem;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0,0,0,.15);
  cursor: pointer;
  &.swiper-pagination-bullet-active{
    background-color: #625999;
  }
}