.font-2{
  font-family: @font2; 
}

.text-gradient{
  display: inline-block;
  background-image: linear-gradient(to right, #ceb9d8, #a3c0e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-2{
  display: inline-block;
  background-image: linear-gradient(to right, #c3afd4, #3f9ad3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// bootstrap 5 - removed
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}
.text-center{
  text-align: center;
}