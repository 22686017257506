@media (min-width: @screen-lg) {
  .fade{
    opacity: 0;
    &.__in{
      opacity: 1;
      transition: opacity .5s ease-out;
    }
  }
  .fade-up{
    opacity: 0;
    transform: translateY(2rem);
    &.__in{
      opacity: 1;
      transform: translateY(0);
      transition: opacity .5s, transform .5s cubic-bezier(0, 0, 0.25, 1);
    }
  }
  .fade-left{
    opacity: 0;
    transform: translateX(2rem);
    &.__in{
      opacity: 1;
      transform: translateX(0);
      transition: opacity .5s, transform .5s cubic-bezier(0, 0, 0.25, 1);
    }
  }
  .fade-right{
    opacity: 0;
    transform: translateX(-3rem);
    &.__in{
      opacity: 1;
      transform: translateX(0);
      transition: opacity .5s, transform .5s cubic-bezier(0, 0, 0.25, 1);
    }
  }
  .fade-left-over{
    opacity: 0;
    transform: translateX(4rem);
    &.__in{
      opacity: 1;
      transform: translateX(0);
      transition: opacity .5s, transform .5s cubic-bezier(0.175, 0.885, 0.320, 1.275);
    }
  }

  // delays
  .delay-1.__in{
    transition-delay: .1s;
  }
  .delay-2.__in{
    transition-delay: .2s;
  }
  .delay-3.__in{
    transition-delay: .3s;
  }
  .delay-4.__in{
    transition-delay: .4s;
  }
  .delay-5.__in{
    transition-delay: .5s;
  }

  // duration
  .slow-1.__in{
    transition-duration: .75s;
  }
  .slow-2.__in{
    transition-duration: 1s;
  }
  .slow-3.__in{
    transition-duration: 1.5s;
  }
}
