@font-face {
  font-family: 'Oregon LDO';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Oregon LDO.woff') format('woff');
}

@font-face {
  font-family: 'Oregon LDO';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Oregon LDO Bold.woff') format('woff');
}

@font-face {
  font-family: 'Optima';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../fonts/Optima Bold.woff') format('woff');
}