.header{
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  height: 3.75rem;
  &-link{
    display: inline-block;
    padding: 1em 0;
    margin: 0 .75em;
    color: #fff;
    fill: currentColor;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 0.1em;
    transition: color .3s;
    &:hover{
      color: #c09eca;
    }
  }
  .logo-white{
    display: block; 
  }
  .logo-color{
    display: none;
  }
  // state
  &.sticky{
    position: fixed;
    background-color: rgba(255,255,255,1);
    color: #000;
    box-shadow: 0 3px 10px rgba(0,0,0,.1);
    opacity: 0;
    &.__in{
      opacity: 1;
      transition: opacity .5s;
    }
    .header-link{
      color: #676767;
      &:hover{
        color: #c09eca;
      }
    }
    .logo-white{
      display: none; 
    }
    .logo-color{
      display: block;
    }
  }
}

.dropdown-wrap{
  position: relative;
}
.dropdown{
  display: none;
  user-select: none;
  &-content{
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 1rem;
    padding-top: .5em;
    padding-bottom: .5em;
    min-width: 100px;
    box-shadow: 0 2px 12px rgba(0,0,0,.15);
    &:before{
      content: '';
      position: absolute;
      z-index: 1;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
    }
  }
  &-item{
    display: block;
    text-align: center;
    padding: .5em .75em;
    color: #000;
    text-decoration: none;
    transition: color .3s;
    &:hover{
      color: #c09eca;
    }
  }
  // state
  &.shown{
    display: block;
  }
  &.shown .dropdown-content{
    opacity: 0;
    transform: translate(-50%, 1rem);
    transition: opacity .3s, transform .3s ease-out;
  }
  &.shown.__in .dropdown-content{
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

// menu
.navigation{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,.9);
  display: none;
  &-link{
    position: relative;
    padding: 1rem;
    text-align: center;
    a{
      font-size: 1.125rem;
      font-weight: 700;
      letter-spacing: 0.25em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #c09eca;
      text-decoration: none;
      text-transform: uppercase;
      transition: color .3s;
      &:before,
      &:after{
        content: '';
        display: inline-block;
        width: 1.3em;
        height: 1em;
        margin: 0 0.75em;
        background-image: url('../../images/ic-menu-shell.svg');
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0;
        transition: opacity .3s;
      }
      &:hover,
      &:focus{
        color: #fff;
        outline: none;
      }
      &:focus:before,
      &:focus:after,
      &:hover:before,
      &:hover:after{
        opacity: 1;
      }
    }
  }
  &-close{
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0 1rem;
    color: #fff;
    fill: currentColor;
    &:hover{
      color: #c09eca;
    }
  }
  // state
  &.active{
    display: block;
    opacity: 0;
    transition: opacity .5s;
    .navigation-link{
      opacity: 0;
      transition: opacity .3s ease-out;
      .ani-loop (@t, @i: 1) when (@i <= @t) {
        &:nth-child(@{i}) {
          transition-delay: 0.5s + ( 0.3s * @i / @t );
        }
        .ani-loop(@t, @i + 1);
      }
      .ani-loop(7);
    }
    &.__in{
      opacity: 1;
      .navigation-link{
        opacity: 1;
      }
    }
  }
}